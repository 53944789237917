import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";
import { ThemeSwitch } from "../../buttons/theme-switch";

export const NavBarTabs = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="nav-bar__tabs">
      <ThemeSwitch/>
      <NavBarTab path="/profile" label="Perfil" />
      <NavBarTab path="/public" label="Noticias" />
      {isAuthenticated && (
        <>
          <NavBarTab path="/protected" label="Ajustes" />
          <NavBarTab path="/admin" label="Admin" />
        </>
      )}
    </div>
  );
};
