function setTheme(themeName){
    localStorage.setItem('theme',themeName);
    document.documentElement.className = themeName;
}

function keepTheme(){
    if(localStorage.getItem('theme')){
        setTheme(localStorage.getItem('theme'));
    }else{
        setTheme('theme-dark');
    }
}

module.exports = {
    setTheme,
    keepTheme
}