import React from "react";
import { PageLayout } from "../components/page-layout";
import { Introduction } from "../components/introduction";
import { AppFeatures } from "../components/app-features";

export const HomePage = () => (
  <PageLayout>
    <Introduction />
    <AppFeatures/>
  </PageLayout>
);
