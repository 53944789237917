import React from "react";

export const Introduction = () => {
  const logo = "https://i.postimg.cc/4xhfF7T0/logo.png";

  return (
    <div className="hero-banner hero-banner--home-screen">
      <div className="hero-banner__logo">
        <img className="hero-banner__image" src={logo} alt="Logo" />
      </div>
      <h1 className="hero-banner__headline">Ya sé que vamos a hacer hoy!</h1>
      <p className="hero-banner__description">
        Esta es una aplicación para elegir que hacer hoy con esa personita especial nwn
      </p>
    </div>
  );
};