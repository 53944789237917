import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src="https://i.postimg.cc/4xhfF7T0/logo.png"
          alt="logo"
          width="45px"
          height="100px"
        />
      </NavLink>
    </div>
  );
};
