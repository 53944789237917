import React from "react";
import { setTheme } from "../../styles/themes";
import { Switch } from 'antd';

export const ThemeSwitch = () => {

    const handleOnChange = () => {
        if (localStorage.getItem('theme') === 'theme-dark'){
            setTheme('theme-light');
        } else {
            setTheme('theme-dark')
        }
    }

    return (
        <div className="theme-switch">
            <label>Cambiar tema: </label>
            <Switch onChange={handleOnChange}/>
        </div>
    )
}
